import { Button, Divider, Input } from 'antd';
import { useUpdateAtom } from 'jotai/utils';
import { FC, useState } from 'react';
import { atoms } from 'shared/atoms';
import { usePeerConnection } from 'shared/components/RTCConnection/remote';

export const RemotePage: FC = () => {
  const setDataChannel = useUpdateAtom(atoms.dataChannel);
  const [sdp, setSdp] = useState<string>();
  const [answer, setAnswer] = useState<string>();
  const [loading, setLoading] = useState(false);
  const PC = usePeerConnection();
  const createRemote = async wrapper => {
    setLoading(true);
    const { offer, iceCandidates } = JSON.parse(wrapper);

    if (PC.remoteIceCandidates.current.length == 0) {
      PC.initializeRTC(offer);
      console.log('no ice candidates yet', PC.remoteIceCandidates.current);
      setTimeout(() => {
        console.log('retry...');
        createRemote(wrapper);
      }, 500);
      return;
    }

    iceCandidates.forEach(ice => {
      PC.peerConnection.current.addIceCandidate(ice).catch(err => console.error(err));
    });
    const answer = PC.peerConnection.current.localDescription;

    const response = { answer, iceCandidates: PC.remoteIceCandidates.current };
    setAnswer(JSON.stringify(response));
    console.log('answer created');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setDataChannel(PC.peerConnection.current.channel);
    setLoading(false);
  };
  return (
    <>
      <h1>Connection state: {PC.peerConnection.current.connectionState}</h1>
      <h1>Join by entering their string</h1>
      <Input.TextArea rows={5} onChange={e => setSdp(e.target.value)} />
      <Button loading={loading} onClick={() => createRemote(sdp)}>
        Join
      </Button>
      <Divider />
      {answer && (
        <>
          <h1>send them this answer</h1>
          <Input.TextArea rows={5} value={answer} />
        </>
      )}
    </>
  );
};
