import { useUpdateAtom } from 'jotai/utils';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chat, JsonObject, Wrapper } from 'shared/@types/webRTC';
import { atoms } from 'shared/atoms';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePeerConnection = () => {
  const navigate = useNavigate();
  const setDataChannel = useUpdateAtom(atoms.dataChannel);
  const setPeerConnection = useUpdateAtom(atoms.peerConnection);
  const setMessages = useUpdateAtom(atoms.messages);
  const setData = useUpdateAtom(atoms.data);
  const peerConnection = useRef(
    new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.services.mozilla.com',
        },
        {
          urls: 'stun:stun.l.google.com:19302',
        },
        {
          urls: ['stun:numb.viagenie.ca'],
          username: 'teun.de.wijs@live.nl',
          credential: 'Rf7$Xc$cW8OJ1wg',
          credentialType: 'password',
        },
      ],
      iceCandidatePoolSize: 10,
    })
  );
  const remoteIceCandidates = useRef<RTCIceCandidate[]>([]);

  const initializeRTC = offer => {
    peerConnection.current.setRemoteDescription(offer).catch(err => console.error(err));
    console.log('incoming offer set succesfully');
    peerConnection.current
      .createAnswer()
      .then(answer => {
        peerConnection.current.setLocalDescription(answer);
      })
      .catch(err => console.error(err));
  };

  peerConnection.current.onicecandidate = event => {
    if (event.candidate) {
      remoteIceCandidates.current.push(event.candidate);
    }
  };
  peerConnection.current.ondatachannel = event => {
    console.log('getting datachannel');

    const dataChannel = event.channel;
    dataChannel.onopen = () => {
      console.log('Connection with client opened');
      setDataChannel(dataChannel);
      setPeerConnection(peerConnection.current);
      navigate('/rtc');
    };

    dataChannel.onmessage = event => {
      const wrapper = JSON.parse(event.data) as Wrapper;
      if (wrapper.messageType === 'chat') setMessages(cur => [...cur, wrapper.content as Chat]);
      if (wrapper.messageType === 'JSON') setData((wrapper.content as JsonObject).data);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    peerConnection.current.channel = dataChannel;
  };

  return { initializeRTC, peerConnection, remoteIceCandidates };
};
