import { Home, Login } from 'modules';
import { LocalPage } from 'modules/home/pages/local';
import { RemotePage } from 'modules/home/pages/remote';
import { RtcPage } from 'modules/home/pages/rtc';
import { Route, Routes } from 'react-router';
import { BaseLayout } from 'shared/components';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/host" element={<LocalPage />} />
        <Route path="/join" element={<RemotePage />} />
        <Route path="/rtc" element={<RtcPage />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};
