import { Button, Divider, Input } from 'antd';
import { useUpdateAtom } from 'jotai/utils';
import { FC, useState } from 'react';
import { atoms } from 'shared/atoms';
import { usePeerConnection } from 'shared/components/RTCConnection/local';

export const LocalPage: FC = () => {
  const setDataChannel = useUpdateAtom(atoms.dataChannel);

  const [sdp, setSdp] = useState<string>();
  const [answer, setAnswer] = useState<string>();
  const [loading, setLoading] = useState(false);
  const PC = usePeerConnection();

  const answerCall = wrapper => {
    const { answer, iceCandidates } = JSON.parse(wrapper);
    PC.peerConnection.current
      ?.setRemoteDescription(answer)
      .then(() => {
        console.log('answer set!');
      })
      .catch(err => console.error(err));
    iceCandidates.forEach(ice => {
      PC.peerConnection.current.addIceCandidate(ice).catch(err => console.error(err));
    });
  };

  const startRtcConnection = () => {
    setLoading(true);
    if (PC.localIceCandidates.current.length == 0) {
      PC.initializeRTC();
      console.log('no ice candidates yet', PC.localIceCandidates.current);
      setTimeout(() => {
        console.log('retry...');
        startRtcConnection();
      }, 500);
      return;
    }
    PC.peerConnection.current
      .createOffer()
      .then(offer => {
        PC.peerConnection.current.setLocalDescription(offer);
        const wrapper = { offer, iceCandidates: PC.localIceCandidates.current };
        console.log('wrapper: ', wrapper);
        setSdp(JSON.stringify(wrapper));
      })
      .catch(err => console.error(err))
      .finally(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setDataChannel(PC.peerConnection.current.channel);
        setLoading(false);
      });
  };

  return (
    <>
      {<h1>Connection state: {PC.peerConnection.current.connectionState}</h1>}
      <>
        <h1>Host by sending this string</h1>
        <Input.TextArea rows={5} value={sdp} />
        <Button loading={loading} onClick={() => startRtcConnection()}>
          Host
        </Button>
        <Divider />
        <h1>Paste their answer here</h1>
        <Input.TextArea rows={5} onChange={e => setAnswer(e.target.value)} />
        <Button onClick={() => answerCall(answer)}>Answer</Button>
      </>
    </>
  );
};
