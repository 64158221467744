import axios, { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter';

const API_URL = window.API_URL;

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL + 'api',
  timeout: 3000,
};

export const api = axios.create(axiosConfig);

api.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export const mockApi = new MockAdapter(api, { delayResponse: 500 });

if (!window.API_MOCK) mockApi.restore();
