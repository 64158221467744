import { ConfigProvider } from 'antd';
import nlNL from 'antd/es/locale/nl_NL';
import { Provider } from 'jotai';
import { FC, Suspense } from 'react';
import { AppRouter } from 'routes';
import { ErrorBoundary } from 'shared/components';
import styleVariables from 'styles/_export.module.scss';
import './app.scss';

ConfigProvider.config({
  theme: {
    primaryColor: styleVariables.primaryColor,
  },
});

export const App: FC = () => (
  <Suspense fallback={<p>loading</p>}>
    <Provider>
      {/* <AuthWrapper> */}
      <ConfigProvider locale={nlNL}>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </ConfigProvider>
      {/* </AuthWrapper> */}
    </Provider>
  </Suspense>
);
