import { useUpdateAtom } from 'jotai/utils';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chat, JsonObject, Wrapper } from 'shared/@types/webRTC';
import { atoms } from 'shared/atoms';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePeerConnection = () => {
  const navigate = useNavigate();
  const setDataChannel = useUpdateAtom(atoms.dataChannel);
  const setPeerConnection = useUpdateAtom(atoms.peerConnection);
  const setMessages = useUpdateAtom(atoms.messages);
  const setData = useUpdateAtom(atoms.data);
  const peerConnection = useRef(
    new RTCPeerConnection({
      iceServers: [
        {
          urls: ['stun:numb.viagenie.ca'],
          username: 'teun.de.wijs@live.nl',
          credential: 'Rf7$Xc$cW8OJ1wg',
          credentialType: 'password',
        },
      ],
      iceCandidatePoolSize: 10,
    })
  );
  const dataChannel = peerConnection.current.createDataChannel('channel');
  const localIceCandidates = useRef<RTCIceCandidate[]>([]);
  const initializeRTC = () => {
    peerConnection.current
      .createOffer({ iceRestart: true })
      .then(offer => {
        peerConnection.current.setLocalDescription(offer);
      })
      .catch(err => console.error(err));
  };
  peerConnection.current.onicecandidate = event => {
    console.log(event.candidate);
    if (event.candidate) localIceCandidates.current.push(event.candidate);
  };
  dataChannel.onopen = () => {
    console.log('Connection opened');
    setDataChannel(dataChannel);
    setPeerConnection(peerConnection.current);
    navigate('/rtc');
  };
  dataChannel.onmessage = event => {
    const wrapper = JSON.parse(event.data) as Wrapper;
    if (wrapper.messageType === 'chat') setMessages(cur => [...cur, wrapper.content as Chat]);
    if (wrapper.messageType === 'JSON') setData((wrapper.content as JsonObject).data);
  };
  return { initializeRTC, peerConnection, localIceCandidates };
};
