import { Button, Input } from 'antd';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { atoms } from 'shared/atoms';

export const Home: FC = () => {
  const [name, setName] = useAtom(atoms.name);
  const navigate = useNavigate();
  return (
    <>
      <h3>Enter your name:</h3>
      <Input onChange={e => setName(e.target.value)} />
      <Button disabled={name.length === 0} onClick={() => navigate('/host')}>
        host
      </Button>
      <Button disabled={name.length === 0} onClick={() => navigate('/join')}>
        join
      </Button>
    </>
  );
};
