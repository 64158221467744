import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Comment, Avatar, Row, Col, Divider, Form, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Chat, JsonObject, Wrapper } from 'shared/@types/webRTC';
import { atoms } from 'shared/atoms';

export const RtcPage: FC = () => {
  const dataChannel = useAtomValue(atoms.dataChannel);
  const peerConnection = useAtomValue(atoms.peerConnection);
  const [messages, setMessages] = useAtom(atoms.messages);
  const [name] = useAtom(atoms.name);
  const [data] = useAtom(atoms.data);
  const [text, setText] = useState('');
  const [form] = Form.useForm();
  const send = () => {
    const message: Chat = { sender: name, message: text, sendDateTime: new Date() };
    const wrapper: Wrapper = { sender: name, messageType: 'chat', content: message };
    dataChannel?.send(JSON.stringify(wrapper));
    setMessages(cur => [...cur, message]);
    setText('');
  };

  useEffect(() => {
    console.log(data);

    if (data.kvk) form.setFieldsValue(data);
  }, [data, form]);

  const sync = values => {
    const object: JsonObject = { data: values };
    const wrapper: Wrapper = { sender: name, messageType: 'JSON', content: object };
    dataChannel?.send(JSON.stringify(wrapper));
  };
  return (
    <>
      {<h1>Connection state: {peerConnection?.connectionState}</h1>}
      <h2>message:</h2>
      <Input value={text} onChange={e => setText(e.target.value)} />
      <Button onClick={() => send()}>Send</Button>
      <br />
      {messages.map((msg, index) => (
        <Row key={index}>
          <Col span={msg.sender === name ? 12 : 2} />
          <Col flex={1}>
            <Comment
              author={msg.sender}
              avatar={<Avatar style={{ backgroundColor: msg.sender === name ? 'blue' : 'green' }}>{msg.sender.substring(0, 1)}</Avatar>}
              datetime={moment(msg.sendDateTime).format('DD-MM-YYYY HH:mm')}
              content={<p>{msg.message}</p>}
            />
          </Col>
        </Row>
      ))}
      <Divider />
      <Form form={form} onFinish={sync}>
        <Form.Item name={['kvk']} labelCol={{ span: 6 }} label="kvk">
          <Input />
        </Form.Item>
        <Form.Item name={['email']} labelCol={{ span: 6 }} label="email adres">
          <Input />
        </Form.Item>
        <Form.Item name={['tel']} labelCol={{ span: 6 }} label="telefoonnummer">
          <Input />
        </Form.Item>
        <Form.Item name={['opmerking']} labelCol={{ span: 6 }} label="Opmerking">
          <TextArea />
        </Form.Item>
        <Form.Item name={['cijfer']} labelCol={{ span: 6 }} label="Cijfer">
          <Select>
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={6}>6</Select.Option>
            <Select.Option value={7}>7</Select.Option>
            <Select.Option value={8}>8</Select.Option>
            <Select.Option value={9}>9</Select.Option>
            <Select.Option value={10}>10</Select.Option>
          </Select>
        </Form.Item>
        <Button htmlType="submit">Sync</Button>
      </Form>
    </>
  );
};
