import { auth } from './auth';
import { dataChannel, messages, peerConnection, data, name } from './webRtc';

export const atoms = {
  auth,
  dataChannel,
  peerConnection,
  messages,
  data,
  name,
};
