import { Card } from 'antd';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const BaseLayout: FC = () => {
  return (
    <div style={{ width: '600px', margin: '0 auto' }}>
      <h1>Demo applicatie</h1>
      <Card>
        <Outlet />
      </Card>
    </div>
  );
};
